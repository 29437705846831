/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar Pedido</h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        class="add-articles-special-margin"
        :disabled="checkDisable || !checkDisableItems"
        @click="openImportPurchaseOrder()"
        >Generar orden desde archivo csv</b-button
      >

      <b-button
        type="is-primary"
        class="add-articles-special-margin"
        :disabled="checkDisable || !checkDisableItems"
        @click="openSelectPredictionDaysModal()"
        >Generar orden automática usando predicción</b-button
      >

      <b-button
        type="is-primary"
        class="add-articles-special-margin"
        :disabled="checkDisable || !checkDisableItems"
        @click="generateSmartRequestMinMax()"
        >Generar orden automática usando máximos y mínimos</b-button
      >

      <b-button
        type="is-primary"
        class="add-articles-special-margin"
        @click="openSelectSale()"
        :disabled="checkDisable || !checkDisableItems"
        >Generar pedido de venta</b-button
      >

      <b-button
        type="is-primary"
        class="add-articles-special-margin"
        @click="openKardex()"
        >Ver movimientos en almacén</b-button
      >
    </div>

    <div class="invoice-add-item">
      <b-field
        class="invoice-add-secondary-item-one"
        label-position="inside"
        label="Comprador (Opc)"
      >
        <b-select
          placeholder="Ejemplo: Sin comprador"
          icon="account-tie"
          expanded
          v-model="buyer"
        >
          <option :value="undefined">Sin comprador</option>
          <option
            :value="singleEmployee._id"
            v-for="singleEmployee in employees"
            :key="singleEmployee._id"
          >
            {{ singleEmployee.NOMBRE }} {{ " " }} {{ singleEmployee.PATERNO }}
            {{ " " }}
            {{ singleEmployee.MATERNO }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four required-input"
        label-position="inside"
        label="Proveedor (Req)"
      >
        <b-autocomplete
          placeholder="Proveedor"
          :data="autoCompleteProviders(provName)"
          v-model="provName"
          field="NOMBRE_PRV"
          autocomplete
          icon="earth"
          open-on-focus
          :disabled="!checkDisableItems"
          expanded
          clearable
          @select="
            (option) => {
              selectedProvider = option;
              restartProvider();
            }
          "
          check-infinite-scroll
        >
          <template #empty>No se encontraron proveedores</template>
        </b-autocomplete>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Fecha (Req)"
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de creación"
          icon="calendar-today"
          v-model="creationDate"
          :disabled="currentIndex > 0"
          trap-focus
          required
        >
        </b-datepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Clave interna (Opc)"
        expanded
      >
        <b-field expanded>
          <b-input
            placeholder="123ABC"
            expanded
            icon="clipboard"
            v-model="NUM_INTERNO"
          />
        </b-field>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four required-input"
        label-position="inside"
        label="Almacenes (Req)"
      >
        <b-taginput
          :data="warehouses"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="NOMBRE_ALM"
          icon="warehouse"
          placeholder="Nombre de almacen"
          check-infinite-scroll
          @add="(value) => addWarehouse(value)"
          @remove="(value) => removeWarehouse(value)"
          :disabled="!checkDisableItems"
          v-model="selectedWarehouses"
        >
          <template #empty>
            <div class="has-text-centered">No hay almacenes</div>
          </template>
        </b-taginput>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-one"
        label-position="inside"
        label="Número de pedido del proveedor (Opc)"
      >
        <b-input
          placeholder="123ABCD"
          expanded
          icon="clipboard-outline"
          v-model="NUM_INTERNO_PROV"
        />
      </b-field>
      <b-field
        class="invoice-add-secondary-item-one"
        label-position="inside"
        label="Fecha prevista de entrega (Opc)"
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de creación"
          icon="calendar-today"
          v-model="FECHA_PRE"
          trap-focus
        >
        </b-datepicker>
      </b-field>
    </div>

    <section ref="requestOrder" v-if="!checkDisable">
      <div class="invoice-box">
        <div class="invoice-upper-part">
          <div class="invoice-logo"></div>
          <div class="invoice-information">
            Fecha de creación: {{ startingDateFormated }}
          </div>
        </div>

        <div class="invoice-company-info-part">
          <div class="invoice-company" v-if="selectedProvider">
            <p v-if="selectedProvider.NOMBRE_PRV">
              {{ selectedProvider.NOMBRE_PRV }}<br />
            </p>
            <p v-if="selectedProvider.DOMICIL_PRV">
              {{ selectedProvider.DOMICIL_PRV }}<br />
            </p>
            <p v-if="selectedProvider.CLAVE_ESTADO && selectedProvider.CP_PRV">
              {{ selectedProvider.CLAVE_CIUDAD }},{{ selectedProvider.CP_PRV }}
              <br />
            </p>
          </div>
          <div class="invoice-company-person" v-if="newPurchaseOrder.PRV"></div>
        </div>

        <div class="invoice-payment-info-part">
          <div class="invoice-payment-type-left">
            <strong>Observaciones</strong>
          </div>
          <div class="invoice-payment-type-right"></div>
        </div>

        <div class="invoice-payment-type-info">
          <div class="invoice-payment-type-info-left invoice-observation">
            <b-input type="textarea" v-model="observations"></b-input>
          </div>
          <div class="invoice-payment-type-info-right"></div>
        </div>

        <div
          v-for="singlePurchaseOrder in newPurchaseOrder.slice(0, 1)"
          :key="singlePurchaseOrder.key"
        >
          <h3 class="purchase-order-table-title">Productos en pedido</h3>
          <div class="table-header-left">
            Peso total: <b>{{ getTotalWeight() | weight }}</b>
          </div>
          <div class="table-header-left">
            Volumen total: <b>{{ getTotalVolumen() | volume }}</b>
          </div>

          <div class="control add-global-controls">
            <b-button
              type="is-primary"
              @click="openAddArticlesSameWord()"
              :disabled="checkDisable"
              >Agregar articulos con misma palabra al pedido</b-button
            >
            <b-button
              type="is-primary"
              @click="openAddMultipleArticles()"
              :disabled="checkDisable"
              >Seleccionar multiples artículos</b-button
            >
            <b-button
              type="is-primary"
              class="add-articles-special-margin"
              @click="openAddShippingCost(newPurchaseOrder)"
              :disabled="checkDisableItems"
              >Agregar costo de flete</b-button
            >

            <b-button
              type="is-primary"
              class="add-articles-special-margin"
              @click="openAddGlobalCost(newPurchaseOrder)"
              :disabled="checkDisableItems"
              >Agregar indirectos globales</b-button
            >
            <b-button
              type="is-primary"
              class="add-articles-special-margin"
              @click="openAddGlobalDiscount(newPurchaseOrder)"
              :disabled="checkDisableItems"
              >Agregar descuentos globales</b-button
            >

            <b-button
              type="is-primary"
              class="add-articles-special-margin"
              @click="roundQuantities()"
              >Redondear cantidades</b-button
            >

            <b-button
              type="is-primary"
              class="add-articles-special-margin"
              @click="roundQuantitiesSpecial()"
              >Redondear cantidades a 5 o 0</b-button
            >

            <b-button
              type="is-primary"
              class="add-articles-special-margin"
              @click="openKardex()"
              >Ver movimientos en almacén</b-button
            >

            <b-button
              type="is-primary"
              class="add-articles-special-margin"
              @click="roundProviderQuantities()"
              >Redondear múltiplos de unidades de proveedor</b-button
            >
          </div>

          <div class="global-lists-search no-margin">
            <div class="global-lists-filters-container"></div>
            <div class="global-lists-filters-columns no-margin">
              <b-checkbox
                v-for="singleColumn in columns"
                v-bind:key="singleColumn.label"
                v-model="singleColumn.display"
              >
                {{ singleColumn.label }}
              </b-checkbox>
            </div>
          </div>

          <b-table
            pagination-position="both"
            :data="singlePurchaseOrder.currentItems"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            ref="purchaseOrder"
            paginated
            :current-page="singlePurchaseOrder.currentPage"
            :per-page="singlePurchaseOrder.perPage"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort-direction="asc"
            class="purchase-order-table"
            scrollable
            detailed
            :custom-row-key="getRandomString()"
            :detail-key="getRandomString()"
          >
            <template #empty>
              <div class="has-text-centered">
                No hay productos en la orden de compra para almacén
                {{ singlePurchaseOrder.ALMACEN.CLAVE_ALMACEN }}
              </div>
            </template>

            <template slot-scope="props">
              <b-table-column label="Acciones" :visible="columns[0].display">
                <b-field class="invoice-option-select">
                  <b-select
                    placeholder="Opciones"
                    v-model="props.row.selectedOption"
                    icon="cog"
                    @input="handleOptionChange(props.row, props)"
                    v-if="props.row.article"
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="deleteItem">Borrar artículo</option>
                    <option value="discount">Editar descuentos</option>
                    <option value="cost">Editar indirectos</option>
                    <option
                      value="restartPrice"
                      v-if="props.row.PRECIO_ORIGINAL"
                    >
                      Restablecer costo
                    </option>
                  </b-select>
                </b-field>
              </b-table-column>

              <!-- eslint-disable vue/no-use-v-if-with-v-for -->
              <b-table-column
                label="Artículo"
                v-if="!props.row.article"
                :visible="columns[1].display"
              >
                <div class="invoice-article-div">
                  <b-button
                    icon-right="magnify"
                    size="is-small"
                    type="is-primary"
                    class="search-article"
                    @click="openSearchArticle(props.row)"
                  >
                  </b-button>
                </div>
              </b-table-column>
              <!-- eslint-enable -->

              <b-table-column
                label="Artículo"
                field="article.DESCRIPCION"
                :visible="props.row.article !== null && columns[1].display"
              >
                <span
                  v-bind:class="{
                    'red-price': getRedPrice(props.row),
                  }"
                  v-if="props.row.article"
                  >{{ props.row.article.DESCRIPCION }}</span
                >
              </b-table-column>

              <b-table-column
                label="Clave del artículo"
                field="article.CLAVE_ART"
                :visible="columns[2].display"
              >
                <span v-if="props.row.article">{{
                  props.row.article.CLAVE_ART
                }}</span>
              </b-table-column>

              <b-table-column
                label="Presentación"
                field="PRESENTATION"
                :visible="columns[3].display"
              >
                <b-field class="invoice-option-select" v-if="props.row.article">
                  <b-select
                    placeholder="Ejemplo: PIEZA"
                    expanded
                    v-model="props.row.PRESENTACION"
                    v-if="props.row.article"
                    class="special-width-input"
                    @input="
                      (value) => {
                        updateAllPresentations(value, props.row.article);
                        updateTotals();
                      }
                    "
                  >
                    <option value="" disabled>Presentaciones</option>
                    <option value="UB" v-if="!props.row.article.UNIDAD_EMP">
                      Unidad de medida base
                    </option>
                    <option value="UB" v-if="props.row.article.UNIDAD_EMP">
                      {{
                        capitalizeString(
                          props.row.article.UNIDAD_EMP.DESCRIP_MED
                        )
                      }}
                    </option>
                    <option
                      v-for="singlePresentation in props.row.article
                        .PRESENTACIONES"
                      :value="singlePresentation._id"
                      :key="singlePresentation._id"
                    >
                      {{ singlePresentation.NOMBRE }}
                    </option>
                  </b-select>
                </b-field>
                <div v-if="!props.row.article">
                  <p>No hay información</p>
                </div>
              </b-table-column>

              <b-table-column label="IVA" :visible="columns[4].display">
                {{ getIva(props.row) + "%" }}
              </b-table-column>

              <b-table-column label="IEPS" :visible="columns[5].display">
                {{ getIeps(props.row) + "%" }}
              </b-table-column>

              <b-table-column
                label="Código del proveedor"
                field="article.providerCode"
                :visible="columns[6].display"
              >
                <span
                  v-if="props.row.article && props.row.article.providerCode"
                  >{{ props.row.article.providerCode }}</span
                >
                <span
                  v-if="props.row.article && !props.row.article.providerCode"
                  >Sin código</span
                >
              </b-table-column>

              <b-table-column
                label="Costo"
                field="price"
                :visible="columns[7].display"
                quantity
              >
                <b-input
                  class="special-width-input"
                  placeholder="Ejemplo: 100"
                  expanded
                  v-model="props.row.price"
                  @input="
                    (value) => {
                      updateArticlePrice(props.row, value);
                      updateTotals(true);
                    }
                  "
                ></b-input>
              </b-table-column>

              <b-table-column
                label="Indirectos"
                field="costTotal"
                :visible="columns[8].display"
              >
                <span v-if="props.row.article">{{
                  props.row.costTotal | money("MXN", 5)
                }}</span>
              </b-table-column>

              <b-table-column
                label="Descuentos totales"
                field="discountTotal"
                :visible="columns[9].display"
              >
                <span v-if="props.row.article">{{
                  props.row.discountTotal | money("MXN", 5)
                }}</span>
              </b-table-column>

              <b-table-column
                label="Cantidad total"
                field="quantity"
                :visible="columns[10].display"
              >
                <b-input
                  placeholder="Ejemplo: 0"
                  expanded
                  :value="getQuantityTotal(props.row.article)"
                  v-if="props.row.article"
                  disabled
                ></b-input>
              </b-table-column>

              <b-table-column
                label="Costo final"
                field="finalPrice"
                sortable
                :v-bind="props.row"
                :visible="columns[11].display"
              >
                <span v-if="props.row.article">{{
                  getPriceTotal(props.row.article) | money("MXN", 2)
                }}</span>
              </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
              <article class="media">
                <div class="media-content">
                  <div class="content">
                    <div class="title-expandable-item">
                      <!-- eslint-disable vue/valid-v-for -->

                      <div
                        v-for="(
                          singlePurchaseOrder, purchaseOrderIndex
                        ) in newPurchaseOrder"
                        v-bind:key="singlePurchaseOrder.CLAVE_PED"
                      >
                        <div class="title-expandable-item">
                          <strong
                            >Detalles para almacén
                            <span class="global-form-hint-color">
                              {{ singlePurchaseOrder.ALMACEN.CLAVE_ALMACEN }}
                            </span></strong
                          >
                        </div>

                        <div class="purchase-order-detail">
                          <b-field
                            v-if="props.row.article"
                            label="Cantidad de piezas pedidas"
                            expanded
                            class="purchsase-order-detail-input"
                            label-position="inside"
                          >
                            <b-input
                              placeholder="Ejemplo: 0"
                              expanded
                              :value="
                                getArticleQuantity(
                                  purchaseOrderIndex,
                                  props.row.article
                                )
                              "
                              @input="
                                (value) => {
                                  changeArticleQuantity(
                                    purchaseOrderIndex,
                                    props.row.article,
                                    value
                                  );
                                  updateTotals(true);
                                }
                              "
                              icon="pencil-plus-outline"
                            ></b-input>
                          </b-field>
                          <b-field
                            v-if="props.row.article"
                            label="Máximo"
                            expanded
                            class="purchsase-order-detail-input"
                            label-position="inside"
                          >
                            <b-input
                              placeholder="Ejemplo: 0"
                              expanded
                              :value="
                                getInventoryMaximum(
                                  singlePurchaseOrder.ALMACEN,
                                  props.row.article
                                )
                              "
                              icon="warehouse"
                              disabled
                            ></b-input>
                          </b-field>
                          <b-field
                            v-if="props.row.article"
                            label="Inventario en almacén"
                            expanded
                            class="purchsase-order-detail-input"
                            label-position="inside"
                          >
                            <b-input
                              placeholder="Ejemplo: 0"
                              expanded
                              :value="
                                getInventoryWarehouse(
                                  singlePurchaseOrder.ALMACEN,
                                  props.row.article,
                                  false
                                )
                              "
                              icon="warehouse"
                              disabled
                            ></b-input>
                          </b-field>
                          <b-field
                            v-if="props.row.article"
                            label="Inventario en almacén de ventas"
                            expanded
                            class="purchsase-order-detail-input"
                            label-position="inside"
                          >
                            <b-input
                              placeholder="Ejemplo: 0"
                              expanded
                              :value="
                                getInventoryWarehouse(
                                  singlePurchaseOrder.ALMACEN,
                                  props.row.article,
                                  true
                                )
                              "
                              icon="warehouse"
                              disabled
                            ></b-input>
                          </b-field>

                          <b-field
                            v-if="props.row.article"
                            label="Multiplo de unidades"
                            expanded
                            class="purchsase-order-detail-input"
                            label-position="inside"
                          >
                            <b-input
                              placeholder="Ejemplo: 0"
                              expanded
                              :value="props.row.MULTIPLO_COMPRA"
                              icon="warehouse"
                              disabled
                            ></b-input>
                          </b-field>

                          <b-field
                            v-if="props.row.article"
                            label="Piezas por surtir"
                            expanded
                            class="purchsase-order-detail-input"
                            label-position="inside"
                          >
                            <b-input
                              placeholder="Ejemplo: 0"
                              expanded
                              :value="
                                getItemBackOrder(
                                  props.row,
                                  singlePurchaseOrder.ALMACEN
                                )
                              "
                              icon="numeric"
                              disabled
                            ></b-input>
                          </b-field>
                          <b-field
                            v-if="
                              props.row.article && props.row.article.maximum
                            "
                            label="Máximo calculado"
                            expanded
                            class="purchsase-order-detail-input"
                            label-position="inside"
                          >
                            <b-input
                              placeholder="Ejemplo: 0"
                              expanded
                              :value="props.row.article.maximum"
                              icon="arrow-up"
                              disabled
                            ></b-input>
                          </b-field>
                        </div>
                      </div>

                      <!-- eslint-enable -->
                    </div>
                  </div>
                </div>
              </article>
            </template>
          </b-table>
        </div>

        <div class="invoice-payment-total-info-costs">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Subtotal:</strong>
            <strong class="invoice-payment-total-money">{{
              this.globalSubtotal | money("MXN", 2)
            }}</strong>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Indirectos:</strong>
            <strong class="invoice-payment-total-money">{{
              this.globalCost | money("MXN", 2)
            }}</strong>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Descuentos:</strong>
            <strong class="invoice-payment-total-money">{{
              this.globalDiscount | money("MXN", 2)
            }}</strong>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Flete:</strong>
            <strong class="invoice-payment-total-money">{{
              (this.shippingQuantity * this.newPurchaseOrder.length)
                | money("MXN", 2)
            }}</strong>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>IVA:</strong>
            <strong class="invoice-payment-total-money">{{
              this.globalIva | money("MXN", 2)
            }}</strong>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>IEPS:</strong>
            <strong class="invoice-payment-total-money">{{
              this.globalIeps | money("MXN", 2)
            }}</strong>
          </div>
        </div>

        <div class="invoice-payment-total-info">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Total:</strong>
            <strong class="invoice-payment-total-money">{{
              this.globalTotal | money("MXN", 2)
            }}</strong>
          </div>
        </div>
      </div>
    </section>
    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="startGenerateReport()"
        :disabled="checkDisableItems"
        >Descargar PDF</b-button
      >
      <b-button
        type="is-success"
        @click="saveNewPurchaseOrder()"
        :disabled="checkDisableItems"
        >Crear pedido</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import moment from 'moment'
import moment from "moment";
import SelectMultipleArticles from "../components/purchase-orders-actions/SelectMultipleArticles.vue";
import PrintMultiplePurchaseOrder from "../../Global/components/print-formats/PrintMultiplePurchaseOrder";
import ChangeProviderPrice from "./purchase-orders-actions/ChangeProviderPrice";
import AddShippingCost from "./purchase-orders-actions/AddShippingCost";
import AddDiscount from "./purchase-orders-actions/AddDiscount";
import AddGlobalCost from "./purchase-orders-actions/AddGlobalCost";
import AddGlobalDiscount from "./purchase-orders-actions/AddGlobalDiscount";
import AddCost from "./purchase-orders-actions/AddCost";
import AddSameWordArticle from "./purchase-orders-actions/AddSameWordArticles";
import SelectArticle from "./purchase-orders-actions/SelectArticle";
import WarehousesTransfers from "../../Global/components/WarehousesTransfers";
import SelectSaleModal from "../../Sales/components/SelectSaleModal";
import { EventBus } from "@/event-bus";
import {
  round,
  toFixedNoRound,
  createRandomString,
  capitalizeFirstLetter,
} from "@/utils/fns";
import ImportFileModal from "@/modules/Global/components/ImportFileModal.vue";
import SelectPredictionDaysModal from "@/modules/Purchases/components/SelectPredictionDaysModal.vue";

export default {
  name: "AddRequestOrder",
  components: {},
  data() {
    return {
      // Table values
      creationDate: new Date(),
      selectedProvider: null,
      creationType: "Manual",
      provName: "",
      newPurchaseOrder: [],
      selectedWarehouses: [],
      observations: "",
      lastNumber: 0,
      shippingQuantity: 0,
      shippingPercentage: 0,
      globalTotal: 0,
      globalSubtotal: 0,
      globalIva: 0,
      globalIeps: 0,
      globalCost: 0,
      globalDiscount: 0,
      globalCosts: [],
      globalDiscounts: [],
      startingDateFormated: "",
      currentIndex: 0,
      buyer: undefined,
      downloading: false,
      downloadingPrices: false,
      providerArticles: [],
      moment: moment,
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "requestOrder1",
            label: "Acciones",
            display: true,
          },
          {
            field: "requestOrder2",
            label: "Artículo",
            display: true,
          },
          {
            field: "requestOrder3",
            label: "Clave del artículo",
            display: true,
          },
          {
            field: "requestOrder4",
            label: "Presentación",
            display: true,
          },
          {
            field: "requestOrder5",
            label: "IVA",
            display: true,
          },
          {
            field: "requestOrder6",
            label: "IEPS",
            display: true,
          },
          {
            field: "requestOrder7",
            label: "Código del proveedor",
            display: true,
          },
          {
            field: "requestOrder8",
            label: "Costo",
            display: true,
          },
          {
            field: "requestOrder9",
            label: "Indirectos",
            display: true,
          },
          {
            field: "requestOrder10",
            label: "Descuentos totales",
            display: true,
          },
          {
            field: "requestOrder11",
            label: "Cantidad total",
            display: true,
          },
          {
            field: "requestOrder12",
            label: "Costo Final",
            display: true,
          },
        ],
        "add-request-orders-list-columns"
      ),
      NUM_INTERNO: "",
      NUM_INTERNO_PROV: "",
      VENTA: undefined,
      selectedSaleInformation: undefined,
      FECHA_PRE: undefined,
    };
  },
  async created() {
    moment.locale("es");
    this.startingDateFormated = moment(new Date()).format("DD-MM-YYYY HH:mm");
  },
  mounted() {
    for (const singleUser of this.users) {
      if (singleUser.email === this.user.email) {
        for (const singleEmployee of this.employees) {
          if (singleUser._id === singleEmployee.USER) {
            this.buyer = singleEmployee._id;
          }
        }
      }
    }

    EventBus.$on("searchArticleSelected", (selection) => {
      let foundItem = 0;
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [singleItemIndex, singleItem] of this.newPurchaseOrder[
          singlePurchaseOrderIndex
        ].currentItems.entries()) {
          if (singleItem.RENGLON === selection.renglon) {
            foundItem = singleItemIndex;
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].article = selection.article;

            if (selection.article.PRESENTACION_COMPRAS) {
              this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
                singleItemIndex
              ].PRESENTACION = selection.article.PRESENTACION_COMPRAS;
            }
          }
        }
      }

      this.getProviderCurrentPrice(
        this.newPurchaseOrder[0].currentItems[foundItem],
        this.selectedProvider
      );
      this.addRow();
      this.updateTotals();
    });

    EventBus.$on("addPurchaseOrderDiscount", (discount) => {
      this.addItemDiscount(discount.article, discount.discounts);
    });

    EventBus.$on("startSmartPurchaseOrder", async (predictionDays) => {
      await this.generateSmartRequest(predictionDays);
    });

    EventBus.$on("addPurchaseOrderCost", (cost) => {
      this.addItemCost(cost.article, cost.costs);
    });

    EventBus.$on("addShippingCost", (cost) => {
      this.shippingQuantity = Number(cost.CANTIDAD).toFixed(5);
      this.updateTotals(true);
    });

    EventBus.$on("saveGlobalDiscounts", (discount) => {
      this.globalDiscounts = discount.discounts;
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        this.newPurchaseOrder[singlePurchaseOrderIndex].DESCS =
          discount.discounts;
      }
      this.updateTotals(true);
    });

    EventBus.$on("saveGlobalCost", (cost) => {
      this.globalCost = cost.costs;
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        this.newPurchaseOrder[singlePurchaseOrderIndex].COSTOS = cost.costs;
      }
      this.updateTotals(true);
    });

    EventBus.$on("addSameWordProducts", (article) => {
      for (const singleArticle of article.articles) {
        if (
          !this.checkRepeatedArticle(singleArticle, this.newPurchaseOrder[0])
        ) {
          this.addRow(singleArticle);
          this.getProviderCurrentPrice(
            this.newPurchaseOrder[0].currentItems[this.currentIndex - 1],
            this.selectedProvider
          );
        }
      }
      this.updateTotals();
      this.addRow();
      this.creationType = `Artículos con misma palabra`;
    });

    EventBus.$on("saleSelected", (selectedSale) => {
      let currentIndex = 0;
      let notFoundedArticles = [];
      let allArticles = [];

      for (const singlePurchaseOrder of this.newPurchaseOrder) {
        singlePurchaseOrder.currentItems = [];
      }
      this.currentIndex = 0;

      for (const singleArticle of this.$store.getters.ARTICLES) {
        let articleWithProps = { ...singleArticle };
        articleWithProps.price = 0;
        articleWithProps.costTotal = 0;
        articleWithProps.discountTotal = 0;
        articleWithProps.costs = [];
        articleWithProps.discounts = [];
        articleWithProps.quantity = 1;
        for (const singleProviderPrice of articleWithProps.PRECIOS_PROV) {
          if (
            singleProviderPrice.CLAVE_PRV === this.selectedProvider._id &&
            (singleArticle.ACTIVO_COMPRA ||
              singleArticle.ACTIVO_COMPRA === undefined ||
              singleArticle.ACTIVO_COMPRA === null)
          ) {
            articleWithProps.costs = singleProviderPrice.COSTOS;
            articleWithProps.discounts = singleProviderPrice.DESCS;
            articleWithProps.price = singleProviderPrice.COSTO;
            articleWithProps.providerCode = singleProviderPrice.CLAVE_ARTPRV;
            allArticles.push(articleWithProps);
          }
        }
      }

      for (const singleItem of selectedSale.sale.VENTA_DET) {
        let found = false;

        for (const singleArticle of allArticles) {
          if (singleArticle._id === singleItem.CLAVE_ART._id) {
            this.addRow(singleArticle);
            this.getProviderCurrentPrice(
              this.newPurchaseOrder[0].currentItems[currentIndex],
              this.selectedProvider
            );
            for (const singlePurchaseOrder of this.newPurchaseOrder) {
              if (!singleArticle.PRESENTACION_COMPRAS) {
                singlePurchaseOrder.currentItems[currentIndex].PRESENTACION =
                  singleItem.PRESENTACION;
              }

              singlePurchaseOrder.currentItems[currentIndex].quantity =
                singleItem.CANTIDAD_PE;
            }
            currentIndex += 1;
            found = true;
          }
        }

        if (!found) {
          notFoundedArticles.push(singleItem);
        }
      }

      this.updateTotals();
      this.roundPresentationUnits();
      this.addRow();

      this.creationType = `Venta con folio ${selectedSale.sale.CLAVE_VENTA}`;
      this.VENTA = selectedSale.sale._id;
      this.selectedSaleInformation = selectedSale.sale;

      if (notFoundedArticles.length > 0) {
        let errorMessage = ``;
        for (let singleItem of notFoundedArticles) {
          errorMessage += `${singleItem.CLAVE_ART.DESCRIPCION}</br>`;
        }

        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Los siguiente productos no fueron agregados ya que no fuerón encontrados en los precios del proveedor:</br>${errorMessage}`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }

      this.updateTotals();
    });

    EventBus.$on("requestOrderConverted", (importData) => {
      let currentIndex = 0;
      let notFoundedArticles = [];
      let allArticles = [];

      for (const singlePurchaseOrder of this.newPurchaseOrder) {
        singlePurchaseOrder.currentItems = [];
      }
      this.currentIndex = 0;

      for (const singleArticle of this.$store.getters.ARTICLES) {
        let articleWithProps = { ...singleArticle };
        articleWithProps.price = 0;
        articleWithProps.costTotal = 0;
        articleWithProps.discountTotal = 0;
        articleWithProps.costs = [];
        articleWithProps.discounts = [];
        articleWithProps.quantity = 1;
        for (const singleProviderPrice of articleWithProps.PRECIOS_PROV) {
          if (
            singleProviderPrice.CLAVE_PRV === this.selectedProvider._id &&
            (singleArticle.ACTIVO_COMPRA ||
              singleArticle.ACTIVO_COMPRA === undefined ||
              singleArticle.ACTIVO_COMPRA === null)
          ) {
            articleWithProps.costs = singleProviderPrice.COSTOS;
            articleWithProps.discounts = singleProviderPrice.DESCS;
            articleWithProps.price = singleProviderPrice.COSTO;
            articleWithProps.providerCode = singleProviderPrice.CLAVE_ARTPRV;
            allArticles.push(articleWithProps);
          }
        }
      }

      for (const singleItem of importData.file) {
        let found = false;
        for (const singleArticle of allArticles) {
          if (
            singleItem["CLAVE DEL ARTICULO (Requerido)"] &&
            singleArticle.CLAVE_ART ===
              singleItem["CLAVE DEL ARTICULO (Requerido)"]
          ) {
            this.addRow(singleArticle);
            this.getProviderCurrentPrice(
              this.newPurchaseOrder[0].currentItems[currentIndex],
              this.selectedProvider
            );
            for (const singlePurchaseOrder of this.newPurchaseOrder) {
              if (singleItem["PRECIO (Requerido)"]) {
                singlePurchaseOrder.currentItems[currentIndex].costs = [];
                singlePurchaseOrder.currentItems[currentIndex].discounts = [];
                singlePurchaseOrder.currentItems[currentIndex].price =
                  singleItem["PRECIO (Requerido)"];
                singlePurchaseOrder.currentItems[currentIndex].PRECIO_ORIGINAL =
                  singleItem["PRECIO (Requerido)"];
                singlePurchaseOrder.currentItems[currentIndex].MULTIPLO_COMPRA =
                  singleItem[
                    "MULTIPLO DE UNIDADES PERMITIDAS EN COMPRA (Opcional)"
                  ];
              }
              if (singleItem["PRESENTACION (Requerido)"]) {
                for (const singlePresentation of singleArticle.PRESENTACIONES) {
                  if (
                    singlePresentation.NOMBRE ===
                    singleItem["PRESENTACION (Requerido)"]
                  ) {
                    singlePurchaseOrder.currentItems[
                      currentIndex
                    ].PRESENTACION = singlePresentation._id;
                  }
                }
              }
              singlePurchaseOrder.currentItems[currentIndex].quantity =
                singleItem["CANTIDAD (Requerido)"];
            }
            currentIndex += 1;
            found = true;
          }
        }

        if (!found) {
          notFoundedArticles.push(singleItem);
        }
      }

      this.updateTotals();
      this.addRow();

      this.creationType = `Importación mediante archivo csv`;

      if (notFoundedArticles.length > 0) {
        let errorMessage = ``;
        for (let singleItem of notFoundedArticles) {
          errorMessage += `${singleItem["CLAVE DEL ARTICULO (Requerido)"]}</br>`;
        }

        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Los siguiente productos no fueron agregados ya que no fuerón encontrados en los precios del proveedor:</br>${errorMessage}`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    });

    EventBus.$on("searchMultipleArticleSelected", (article) => {
      for (const singleArticle of article.articles) {
        if (
          !this.checkRepeatedArticle(singleArticle, this.newPurchaseOrder[0])
        ) {
          this.addRow(singleArticle);
          this.getProviderCurrentPrice(
            this.newPurchaseOrder[0].currentItems[this.currentIndex - 1],
            this.selectedProvider
          );
        }
      }

      this.updateTotals();
      this.addRow();
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    capitalizeString(string) {
      return capitalizeFirstLetter(string);
    },
    restartProvider() {
      this.newPurchaseOrder = [];
      this.selectedWarehouses = [];
    },
    addWarehouse(warehouse) {
      const newPurchaseOrderData = {
        PRV: this.selectedProvider,
        ALMACEN: warehouse,
        COSTOS: [],
        DESCS: [],
        OBSERVA_PED: "",
        currentItems: [],
        creationDate: this.creationDate,
        subtotal: 0,
        total: 0,
        discounts: 0,
        costs: 0,
        iva: 0,
        ieps: 0,
        exempt: 0,
        shippingQuantity: 0,
        shippingPercentage: 0,
        printTimes: 0,
        status: "Sin verificar",
        perPage: 10,
        currentPage: 1,
        EXENTO: 0,
        key: this.getRandomString(),
      };

      if (
        this.selectedProvider.PORC_DESCUENTO &&
        this.selectedProvider.PORC_DESCUENTO > 0
      ) {
        newPurchaseOrderData.DESCS.push({
          NOMBRE: "Descuento de proveedor",
          DESCRIPCION: "Descuento de proveedor",
          TIPO: "PO",
          PORCENTAJE: Number(this.selectedProvider.PORC_DESCUENTO),
          CANTIDAD: 0,
        });
      }

      this.newPurchaseOrder.push(newPurchaseOrderData);

      this.warehouseChange();
    },
    removeWarehouse(warehouse) {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        if (singlePurchaseOrder.ALMACEN._id === warehouse._id) {
          this.newPurchaseOrder.splice(singlePurchaseOrderIndex, 1);
          return;
        }
      }

      this.warehouseChange();
    },
    /**
     *
     * @desc Everytime a warehouse is changed then add new item to current items
     * so all purchase order have at least one item when editing
     */
    warehouseChange() {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems = [];
      }
      this.currentIndex = 0;
      this.addRow(undefined, false);
    },
    isWarehouseeSelected(warehouse) {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        if (singlePurchaseOrder.ALMACEN._id === warehouse._id) {
          return true;
        }
      }
      return false;
    },
    checkArticleRepeated(article, purchaseOrder) {
      for (const singleItem of purchaseOrder.currentItems) {
        if (
          singleItem.article &&
          singleItem.article.CLAVE_ART === article.CLAVE_ART
        ) {
          return false;
        }
      }
      return true;
    },
    async saveNewPurchaseOrderLogic() {
      try {
        let savePurchaseOrderData = this.transformPurchaseOrder(
          this.newPurchaseOrder
        );
        let response = await this.$store.dispatch(
          "SAVEREQUESTORDER",
          savePurchaseOrderData
        );
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente el nuevo pedido",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar el nuevo pedido",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el nuevo pedido en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async saveNewPurchaseOrder() {
      try {
        const userQuantityValidation = this.validateProviderQuantity();
        if (!userQuantityValidation.success) {
          this.$buefy.dialog.confirm({
            title: "¿Esta segro que desea continuar?",
            message: `Revise que la cantidad de unidades compradas sea múltiplo de las unidades permitidas por el proveedor: ${userQuantityValidation.message} ¿Esta segro que desea continuar?`,
            confirmText: "Continuar",
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
              await this.saveNewPurchaseOrderLogic();
            },
            cancelText: "Cancelar",
            canCancel: true,
          });
        } else {
          await this.saveNewPurchaseOrderLogic();
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el nuevo pedido en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    transformPurchaseOrder(purchaseOrder) {
      let transformedPurchaseOrders = [];
      for (let singlePurchaseOrder of purchaseOrder) {
        if (singlePurchaseOrder.total > 0) {
          let transformedPurchaseOrder = {};
          transformedPurchaseOrder.CLAVE_PED = singlePurchaseOrder.CLAVE_PED;
          transformedPurchaseOrder.TIPO = "P";
          transformedPurchaseOrder.OBSERVA_PED = this.observations;
          transformedPurchaseOrder.FECHA_PED = this.creationDate;
          transformedPurchaseOrder.CLAVE_ALMACEN =
            singlePurchaseOrder.ALMACEN._id;
          transformedPurchaseOrder.CLAVE_PRV = singlePurchaseOrder.PRV._id;
          transformedPurchaseOrder.TOTAL_PED = singlePurchaseOrder.total;
          transformedPurchaseOrder.SUBTOTAL_PED = singlePurchaseOrder.subtotal;
          transformedPurchaseOrder.IVA_PED = singlePurchaseOrder.iva;
          transformedPurchaseOrder.FLETE_PED = this.shippingQuantity;
          transformedPurchaseOrder.PORC_FLETEP =
            singlePurchaseOrder.shippingPercentage;
          transformedPurchaseOrder.EXENTO = singlePurchaseOrder.EXENTO;
          transformedPurchaseOrder.IEPS = singlePurchaseOrder.ieps;
          transformedPurchaseOrder.STATUS_PED = "P";
          transformedPurchaseOrder.COSTOS = singlePurchaseOrder.COSTOS;
          transformedPurchaseOrder.COSTOS_GTO = singlePurchaseOrder.costs;
          transformedPurchaseOrder.DESCS = singlePurchaseOrder.DESCS;
          transformedPurchaseOrder.DESCS_GTO = singlePurchaseOrder.discounts;
          transformedPurchaseOrder.IMPRESO = singlePurchaseOrder.printTimes;
          transformedPurchaseOrder.COMPRADOR = this.buyer;
          transformedPurchaseOrder.EMAIL_GUA = this.user.email;
          transformedPurchaseOrder.EMAIL_ACT = this.user.email;
          transformedPurchaseOrder.NUM_INTERNO = this.NUM_INTERNO;
          transformedPurchaseOrder.NUM_INTERNO_PROV = this.NUM_INTERNO_PROV;
          transformedPurchaseOrder.FECHA_PRE = this.FECHA_PRE;
          transformedPurchaseOrder.VENTA = this.VENTA;
          transformedPurchaseOrder.TIPO_CREACION = this.creationType;

          let items = [];
          for (let singleItem of singlePurchaseOrder.currentItems) {
            if (singleItem.article && singleItem.quantity > 0) {
              items.push({
                CLAVE_ART: singleItem.article._id,
                RENGLON: singleItem.RENGLON,
                CANTIDAD_PE: singleItem.quantity,
                CANTIDAD_RE: 0,
                CANTIDAD_PR: singleItem.quantity,
                PORC_IVA: this.getIva(singleItem),
                PORC_IEPS: this.getIeps(singleItem),
                IVA_GTO: singleItem.IVA_GTO,
                IEPS_GTO: singleItem.IEPS_GTO,
                DESCTO_PAR: singleItem.discountTotal,
                COSTO_PAR: singleItem.costTotal,
                COSTO_UNI: singleItem.price,
                DESCS: singleItem.discounts,
                COSTOS: singleItem.costs,
                TOTAL: singleItem.totalItem,
                EXENTO: singleItem.EXENTO,
                PRESENTACION: singleItem.PRESENTACION,
                CANTIDAD_PRESENTACION: singleItem.CANTIDAD_PRESENTACION,
                PRECIO_ORIGINAL: singleItem.PRECIO_ORIGINAL,
                MULTIPLO_COMPRA: singleItem.MULTIPLO_COMPRA,
              });
            }
          }
          transformedPurchaseOrder.items = items;
          transformedPurchaseOrders.push(transformedPurchaseOrder);
        }
      }
      return transformedPurchaseOrders;
    },
    roundPresentationUnits() {
      for (const [
        purchaseOrderIndex,
        purchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [
          purchaseOrderItemIndex,
          purchaseOrderItem,
        ] of purchaseOrder.currentItems.entries()) {
          this.newPurchaseOrder[purchaseOrderIndex].currentItems[
            purchaseOrderItemIndex
          ].quantity =
            purchaseOrderItem.quantity /
            purchaseOrderItem.CANTIDAD_PRESENTACION;
        }
      }
    },
    checkRepeatedArticle(article, purchaseOrder) {
      for (const singleItem of purchaseOrder.currentItems) {
        if (singleItem.article && singleItem.article._id === article._id) {
          return true;
        }
      }
      return false;
    },
    validateProviderQuantity() {
      let success = true;
      let message = "\n";

      for (let singlePurchaseOrder of this.newPurchaseOrder) {
        for (let singleItem of singlePurchaseOrder.currentItems) {
          if (
            singleItem.article &&
            singleItem.quantity > 0 &&
            singleItem.MULTIPLO_COMPRA &&
            singleItem.quantity % singleItem.MULTIPLO_COMPRA !== 0 &&
            singleItem.PRESENTACION === "UB"
          ) {
            success = false;
            message += `${singleItem.article.CLAVE_ART}: ${singleItem.MULTIPLO_COMPRA}\n`;
          }
        }
      }

      return { success, message };
    },
    roundProviderQuantities() {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [
          singleArticleIndex,
          singleArticle,
        ] of singlePurchaseOrder.currentItems.entries()) {
          if (
            singleArticle.article &&
            singleArticle.quantity > 0 &&
            singleArticle.MULTIPLO_COMPRA &&
            singleArticle.quantity % singleArticle.MULTIPLO_COMPRA !== 0
          ) {
            let quantityRound =
              Math.ceil(
                singleArticle.quantity / singleArticle.MULTIPLO_COMPRA
              ) * singleArticle.MULTIPLO_COMPRA;

            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleArticleIndex
            ].quantity = quantityRound;
          }
        }
      }

      this.updateTotals();
      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Redondeo realizado con éxito",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {},
        cancelText: "cancelar",
        canCancel: false,
      });
    },
    /**
     *
     * @desc adds a new row to the current purchase order, a row is added when article is selected or added
     */
    addRow(article = null, incrementIndex) {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        let newArticleInformation = {
          article: article,
          quantity: 1,
          RENGLON: this.currentIndex,
          price: 0,
          finalPrice: 0,
          costTotal: 0,
          discountTotal: 0,
          costs: [],
          discounts: [],
          selectedOption: "",
        };

        if (article && article.PRESENTACION_COMPRAS) {
          newArticleInformation.PRESENTACION = article.PRESENTACION_COMPRAS;
        }

        this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems.push(
          newArticleInformation
        );
      }

      this.currentIndex++;
    },
    startGenerateReport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintMultiplePurchaseOrder,
        props: {
          canConfirm: false,
          title: "Pedido",
          purchaseOrderInformation: this.newPurchaseOrder,
          observationsInformation: this.observations,
          buyer: this.buyer,
          saleInformation: this.selectedSaleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddGlobalCost() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalCost,
        props: {
          allCosts: this.globalCosts,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddGlobalDiscount() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalDiscount,
        props: {
          allDiscounts: this.globalDiscounts,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    generateReport(type) {
      this.$buefy.dialog.confirm({
        title: "Generar PDF con precios o sin precios",
        message:
          "El archivo será exportado en formato PDF selecciones la opción deseada.",
        confirmText: "Con precios",
        cancelText: "Sin precios",
        type: "is-primary",
        hasIcon: true,
        canCancel: true,
        onConfirm: () => this.generateReport(0),
        onCancel: () => this.generateReport(1),
      });
    },
    openSelectPredictionDaysModal() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectPredictionDaysModal,
        props: {},
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async generateSmartRequest(predictionDays) {
      try {
        let response = await this.$store.dispatch("GENERATESMARTORDER", {
          provider: this.selectedProvider,
          warehouses: this.selectedWarehouses,
          typeOfOrder: "PI",
          days: predictionDays.DIAS,
        });
        if (response.success) {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se generó exitosamente la orden de compra automática",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
          if (response.articles.length > 0) {
            this.addGeneratedRequestItems(response.articles);
          }
          this.creationType = `Orden automática usando predicción`;
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al generar la orden de compra automática",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al generar la orden de compra automática",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async generateSmartRequestMinMax() {
      try {
        let response = await this.$store.dispatch("GENERATESMARTORDERMINMAX", {
          provider: this.selectedProvider,
          warehouses: this.selectedWarehouses,
          typeOfOrder: "PI",
        });

        if (response) {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se generó exitosamente la orden de compra automática",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
          this.addGeneratedRequestItems(response);
          this.creationType = `Orden automática usando máximos y mínimos`;
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al generar la orden de compra automática",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al generar la orden de compra automática",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc adds smart request order items to the current request orders
     */
    addGeneratedRequestItems(items) {
      let allArticles = [];

      // Restart request orders
      for (const singleRequestOrder of this.newPurchaseOrder) {
        singleRequestOrder.currentItems = [];
      }
      this.currentIndex = 0;

      for (const singleArticle of this.$store.getters.ARTICLES) {
        let articleWithProps = { ...singleArticle };
        articleWithProps.price = 0;
        articleWithProps.costTotal = 0;
        articleWithProps.discountTotal = 0;
        articleWithProps.costs = [];
        articleWithProps.discounts = [];
        articleWithProps.quantity = 1;
        for (const singleProviderPrice of articleWithProps.PRECIOS_PROV) {
          if (
            singleProviderPrice.CLAVE_PRV === this.selectedProvider._id &&
            (singleArticle.ACTIVO_COMPRA ||
              singleArticle.ACTIVO_COMPRA === undefined ||
              singleArticle.ACTIVO_COMPRA === null)
          ) {
            articleWithProps.costs = singleProviderPrice.COSTOS;
            articleWithProps.discounts = singleProviderPrice.DESCS;
            articleWithProps.price = singleProviderPrice.COSTO;
            articleWithProps.providerCode = singleProviderPrice.CLAVE_ARTPRV;
            allArticles.push(articleWithProps);
          }
        }
      }

      // Add every generated item to the purchase orders
      for (const singleItem of items) {
        // Move trought the provider articles
        for (const singleArticle of allArticles) {
          // If article is found
          if (singleArticle._id === singleItem.article) {
            // Move through the warehouses in current request orders
            for (const [
              singlePurchaseOrderIndex,
              singlePurchaseOrder,
            ] of this.newPurchaseOrder.entries()) {
              let quantityToAdd = 0;
              let maximumItem = 0;
              for (const singleItemWarehouse of singleItem.warehouses) {
                // If warehouse was found
                if (
                  singlePurchaseOrder.ALMACEN._id ===
                  singleItemWarehouse.warehouse
                ) {
                  quantityToAdd = singleItemWarehouse.quantity;
                  maximumItem = singleItemWarehouse.maximum;
                  break;
                }
              }
              // Add new article
              let articleWithProps = { ...singleArticle };
              articleWithProps.maximum = Math.ceil(maximumItem);
              let newItemRow = {
                article: articleWithProps,
                quantity: quantityToAdd ? quantityToAdd : 0,
                RENGLON: this.currentIndex,
                price: 0,
                finalPrice: 0,
                costTotal: 0,
                discountTotal: 0,
                costs: [],
                discounts: [],
                selectedOption: "",
              };

              if (articleWithProps.PRESENTACION_COMPRAS) {
                newItemRow.PRESENTACION = articleWithProps.PRESENTACION_COMPRAS;
              }

              this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems.push(
                newItemRow
              );
              let priceResult = this.getProviderCurrentPriceNoUpdate(
                this.newPurchaseOrder[singlePurchaseOrderIndex],
                newItemRow,
                this.selectedProvider,
                quantityToAdd ? quantityToAdd : 0
              );
              if (!priceResult)
                this.newPurchaseOrder[
                  singlePurchaseOrderIndex
                ].currentItems.splice(-1);
            }
            this.currentIndex += 1;
            break;
          }
        }
      }

      this.updateTotals();
      this.roundPresentationUnits();
      this.updateTotals();
      this.addRow();
    },
    getProviderCurrentPrice(item, providerInformation) {
      let foundItemIndex;

      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder[0].currentItems.entries()) {
        if (
          item &&
          singleItem.article &&
          singleItem.article.CLAVE_ART === item.article.CLAVE_ART
        ) {
          foundItemIndex = singleItemIndex;
        }
      }

      item.price = 0;
      item.PRECIO_ORIGINAL = 0;
      item.MULTIPLO_COMPRA = 0;
      item.costTotal = 0;
      item.discountTotal = 0;
      item.costs = [];
      item.quantity = 1;
      item.discounts = [];
      item.EXENTO = "N";
      item.IVA_GTO = 0;
      item.IEPS_GTO = 0;
      item.PRESENTACION = item.PRESENTACION ? item.PRESENTACION : "UB";
      item.CANTIDAD_PRESENTACION = 1;

      for (const singleProviderPrice of item.article.PRECIOS_PROV) {
        if (singleProviderPrice.CLAVE_PRV === providerInformation._id) {
          item.costs = singleProviderPrice.COSTOS;
          item.discounts = singleProviderPrice.DESCS;
          item.price = singleProviderPrice.COSTO;
          item.PRECIO_ORIGINAL = singleProviderPrice.COSTO;
          item.MULTIPLO_COMPRA = singleProviderPrice.MULTIPLO_COMPRA;
          break;
        }
      }

      for (const [
        singleOrderIndex,
        singleOrder,
      ] of this.newPurchaseOrder.entries()) {
        if (singleOrderIndex !== 0) {
          this.newPurchaseOrder[singleOrderIndex].currentItems[foundItemIndex] =
            { ...item };
        }
      }

      if (item.article && item.article.TIPO_IVA === "EX") {
        item.EXENTO = "S";
      }

      this.updateTotals(true);
      return;
    },
    getQuantityTotal(article) {
      let foundItem;
      let totalQuantity = 0;

      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder[0].currentItems.entries()) {
        if (
          singleItem.article &&
          singleItem.article.CLAVE_ART === article.CLAVE_ART
        ) {
          foundItem = singleItemIndex;
        }
      }

      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        totalQuantity += Number(
          singlePurchaseOrder.currentItems[foundItem].quantity
        );
      }

      return totalQuantity;
    },

    getPriceTotal(article) {
      let foundItem;
      let totalQuantity = 0;

      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder[0].currentItems.entries()) {
        if (
          singleItem.article &&
          singleItem.article.CLAVE_ART === article.CLAVE_ART
        ) {
          foundItem = singleItemIndex;
        }
      }

      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        totalQuantity += Number(
          singlePurchaseOrder.currentItems[foundItem].finalPrice
        );
      }

      return totalQuantity;
    },
    getTotalWeight() {
      let totalWeight = 0;
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [
          singleArticleIndex,
          singleArticle,
        ] of singlePurchaseOrder.currentItems.entries()) {
          if (singleArticle && singleArticle.article) {
            if (singleArticle.article.CANT_PESO) {
              totalWeight +=
                singlePurchaseOrder.currentItems[singleArticleIndex].quantity *
                singleArticle.article.CANT_PESO;
            }
          }
        }
      }
      return totalWeight;
    },
    getTotalVolumen() {
      let totalWeight = 0;
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [
          singleArticleIndex,
          singleArticle,
        ] of singlePurchaseOrder.currentItems.entries()) {
          if (singleArticle && singleArticle.article) {
            if (singleArticle.article.CANT_VOL) {
              totalWeight +=
                singlePurchaseOrder.currentItems[singleArticleIndex].quantity *
                singleArticle.article.CANT_VOL;
            }
          }
        }
      }
      return totalWeight;
    },
    getProviderCurrentPriceNoUpdate(
      purchaseOrder,
      item,
      providerInformation,
      quantity
    ) {
      item.price = 0;
      item.PRECIO_ORIGINAL = 0;
      item.MULTIPLO_COMPRA = 0;
      item.costTotal = 0;
      item.discountTotal = 0;
      item.costs = [];
      item.discounts = [];
      item.quantity = quantity;
      item.EXENTO = "N";
      item.PRESENTACION = "UB";
      item.CANTIDAD_PRESENTACION = 1;

      for (const singleProviderPrice of item.article.PRECIOS_PROV) {
        if (singleProviderPrice.CLAVE_PRV === providerInformation._id) {
          if (singleProviderPrice.COSTO === 0) return false;
          item.costs = singleProviderPrice.COSTOS;
          item.discounts = singleProviderPrice.DESCS;
          item.price = singleProviderPrice.COSTO;
          item.PRECIO_ORIGINAL = singleProviderPrice.COSTO;
          item.MULTIPLO_COMPRA = singleProviderPrice.MULTIPLO_COMPRA;
          break;
        }
      }

      if (item.article && item.article.TIPO_IVA === "EX") {
        item.EXENTO = "S";
      }

      if (item.article && item.article.PRESENTACION_COMPRAS) {
        item.PRESENTACION = item.article.PRESENTACION_COMPRAS;
      }

      return true;
    },
    updateAllPresentations(presentation, article) {
      for (const purchaseOrder of this.newPurchaseOrder) {
        for (const item of purchaseOrder.currentItems) {
          if (item.article && item.article._id === article._id) {
            item.PRESENTACION = presentation;
          }
        }
      }
    },
    deleteRow(item) {
      let foundArticleIndex = 0;

      // find article index in current items
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder[0].currentItems.entries()) {
        if (
          item.article &&
          singleItem.article &&
          item.article._id === singleItem.article._id
        ) {
          foundArticleIndex = singleItemIndex;
        }
      }

      // Delete item
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems.splice(
          foundArticleIndex,
          1
        );

        this.newPurchaseOrder[singlePurchaseOrderIndex].key =
          this.getRandomString();
      }

      this.updateTotals(true);
    },
    openChangePrice(item, provider) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ChangeProviderPrice,
        props: {
          item: item,
          provider: provider,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddCost(item, purchaseOrderIndex) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCost,
        props: {
          isGeneral: true,
          item: item,
          isPurchaseOrder: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddDiscount(item) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddDiscount,
        props: {
          isGeneral: true,
          item: item,
          isPurchaseOrder: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddShippingCost() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddShippingCost,
        props: {
          shippingQuantity: this.shippingQuantity,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handleOptionChange(item, props) {
      if (item.selectedOption === "deleteItem") {
        this.deleteRow(item);
      } else if (item.selectedOption === "discount") {
        this.openAddDiscount(item);
      } else if (item.selectedOption === "cost") {
        this.openAddCost(item);
      } else if (item.selectedOption === "restartPrice") {
        item.price = item.PRECIO_ORIGINAL;
        this.updateTotals();
      }
    },
    addItemDiscount(item, discounts) {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [singleItemIndex, singleItem] of this.newPurchaseOrder[
          singlePurchaseOrderIndex
        ].currentItems.entries()) {
          if (singleItem.article && singleItem.article._id === item._id) {
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].discounts = discounts;
            break;
          }
        }
      }
      this.updateTotals(true);
    },
    addItemCost(item, costs) {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [singleItemIndex, singleItem] of this.newPurchaseOrder[
          singlePurchaseOrderIndex
        ].currentItems.entries()) {
          if (singleItem.article && singleItem.article._id === item._id) {
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].costs = costs;
            break;
          }
        }
      }
      this.updateTotals(true);
    },
    getIva(item) {
      if (item.article) {
        if (
          item.article.CANT_IVA !== undefined &&
          item.article.CANT_IVA !== null
        ) {
          return item.article.CANT_IVA;
        } else {
          return 16;
        }
      }
      return 0;
    },
    getIeps(item) {
      if (item.article) {
        if (
          item.article.CANT_IEPS !== undefined &&
          item.article.CANT_IEPS !== null
        ) {
          return item.article.CANT_IEPS;
        } else {
          return 0;
        }
      }
      return 0;
    },
    getRedPrice(item) {
      if (item.article) {
        if (item.article.DESCONTINUADO) {
          return true;
        }

        return item.price >= item.article.COSTO_MIN_VTA;
      }

      return false;
    },
    getInventoryMaximum(warehouse, article) {
      for (const singleWarehouse of article.CANT_ALMACENES) {
        if (singleWarehouse.CLAVE_ALMACEN === warehouse._id) {
          return singleWarehouse.MAXIMO;
        }
      }
      return 0;
    },
    getInventoryWarehouse(warehouse, article, saleInventory = false) {
      for (const singleWarehouse of article.CANT_ALMACENES) {
        if (singleWarehouse.CLAVE_ALMACEN === warehouse._id) {
          let totalQuantity = round(Number(singleWarehouse.CANTIDAD), 4);
          if (saleInventory) {
            if (singleWarehouse.CANTIDAD_VENTAS) {
              totalQuantity += round(
                Number(singleWarehouse.CANTIDAD_VENTAS),
                4
              );
            }

            if (singleWarehouse.CANTIDAD_VIRTUAL) {
              totalQuantity += round(
                Number(singleWarehouse.CANTIDAD_VIRTUAL),
                4
              );
            }
          }

          return totalQuantity;
        }
      }
      return 0;
    },
    updateTotals(noUpdate = false) {
      this.shippingQuantity = round(
        Number(this.shippingQuantity).toFixed(5),
        2
      );

      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        let total = 0;
        let subtotalNoExtras = 0;
        let subtotal = 0;
        let iva = 0;
        let ieps = 0;
        let cost = 0;
        let costsTotal = 0;
        let discount = 0;
        let discountsTotal = 0;
        let excent = 0;
        for (const [
          singleItemIndex,
          singleItem,
        ] of singlePurchaseOrder.currentItems.entries()) {
          if (singleItem.article) {
            let costItem = 0;
            let discountItem = 0;

            // Check if it's a presentation or default measure
            if (!noUpdate) {
              if (singleItem.PRESENTACION === "UB") {
                singleItem.CANTIDAD_PRESENTACION = 1;
                singleItem.price = round(singleItem.PRECIO_ORIGINAL, 2);
              } else {
                singleItem.price = round(singleItem.PRECIO_ORIGINAL, 2);
                singleItem.CANTIDAD_PRESENTACION = 1;

                for (const singlePresentation of singleItem.article
                  .PRESENTACIONES)
                  if (singleItem.PRESENTACION === singlePresentation._id) {
                    singleItem.price = singleItem.PRECIO_ORIGINAL;
                    singleItem.CANTIDAD_PRESENTACION = 1;

                    if (singlePresentation.ESMAYOR === "S") {
                      singleItem.price =
                        singleItem.price * singlePresentation.FACTOR2;

                      singleItem.CANTIDAD_PRESENTACION =
                        1 * singlePresentation.FACTOR2;
                    } else {
                      singleItem.price =
                        singleItem.price / singlePresentation.FACTOR2;

                      singleItem.CANTIDAD_PRESENTACION =
                        1 / singlePresentation.FACTOR2;
                    }
                  }
              }
            }

            let priceQuantity = singleItem.price * singleItem.quantity;

            for (const cost of singleItem.costs) {
              costItem +=
                toFixedNoRound(cost.CANTIDAD, 2) *
                singleItem.CANTIDAD_PRESENTACION;
            }

            costsTotal += singleItem.quantity * costItem;

            for (const discount of singleItem.discounts) {
              discountItem +=
                toFixedNoRound(discount.CANTIDAD, 2) *
                singleItem.CANTIDAD_PRESENTACION;
            }

            discountsTotal += singleItem.quantity * discountItem;

            let subTotalItem =
              priceQuantity +
              singleItem.quantity * costItem -
              singleItem.quantity * discountItem;

            subtotal += subTotalItem;
            subtotalNoExtras += priceQuantity;

            let totalItem = 0;
            totalItem += subTotalItem;

            // Add Iva to the total
            let itemIva = subTotalItem * (this.getIva(singleItem) / 100);
            totalItem += itemIva;
            iva += itemIva;

            // Add Ieps to the total
            let itemIeps = subTotalItem * (this.getIeps(singleItem) / 100);
            totalItem += itemIeps;
            ieps += itemIeps;

            // Add iva excent to excent total
            if (singleItem.article && singleItem.article.TIPO_IVA === "EX") {
              excent += subTotalItem;
            }

            // calculate final cost value
            let finalDiscountItemValue = singleItem.quantity * discountItem;
            let finalCostValue = priceQuantity - finalDiscountItemValue;
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].discountTotal = toFixedNoRound(discountItem, 3);
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].costTotal = toFixedNoRound(costItem, 3);
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].finalPrice = toFixedNoRound(finalCostValue, 3);
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].totalItem = toFixedNoRound(totalItem, 3);
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].IVA_GTO = toFixedNoRound(itemIva, 3);
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].IEPS_GTO = toFixedNoRound(itemIeps, 3);
          }
        }

        let currentSubtotal = subtotal;

        for (let singleDiscount of this.newPurchaseOrder[
          singlePurchaseOrderIndex
        ].DESCS) {
          let ivaPercentage = iva / currentSubtotal;
          let iepsPercentage = ieps / currentSubtotal;

          if (singleDiscount.TIPO === "PO") {
            let discountQuantity =
              currentSubtotal * (singleDiscount.PORCENTAJE / 100);
            currentSubtotal -= discountQuantity;
            discount += discountQuantity;
            discountsTotal += discountQuantity;

            iva -= ivaPercentage * discountQuantity;
            ieps -= iepsPercentage * discountQuantity;
          } else if (singleDiscount.TIPO === "CA") {
            discount += Number(singleDiscount.CANTIDAD);
            discountsTotal += Number(singleDiscount.CANTIDAD);

            iva -= ivaPercentage * Number(singleDiscount.CANTIDAD);
            ieps -= iepsPercentage * Number(singleDiscount.CANTIDAD);
          }
        }

        for (let singleCost of this.newPurchaseOrder[singlePurchaseOrderIndex]
          .COSTOS) {
          let ivaPercentage = iva / currentSubtotal;
          let iepsPercentage = iva / currentSubtotal;

          if (singleCost.TIPO === "PO") {
            let costQuantity = currentSubtotal * (singleCost.PORCENTAJE / 100);
            currentSubtotal -= costQuantity;
            cost += costQuantity;
            costsTotal += costQuantity;

            iva += ivaPercentage * costQuantity;
            ieps += iepsPercentage * costQuantity;
          } else if (singleCost.TIPO === "CA") {
            cost += Number(singleCost.CANTIDAD);
            costsTotal += Number(singleCost.CANTIDAD);

            iva += ivaPercentage * Number(singleCost.CANTIDAD);
            ieps += iepsPercentage * Number(singleCost.CANTIDAD);
          }
        }

        total +=
          subtotal -
          discountsTotal +
          costsTotal +
          iva +
          ieps +
          this.shippingQuantity;

        this.newPurchaseOrder[singlePurchaseOrderIndex].subtotal =
          toFixedNoRound(subtotalNoExtras, 3);
        this.newPurchaseOrder[singlePurchaseOrderIndex].total = toFixedNoRound(
          total,
          3
        );
        this.newPurchaseOrder[singlePurchaseOrderIndex].discounts =
          toFixedNoRound(discountsTotal, 3);
        this.newPurchaseOrder[singlePurchaseOrderIndex].costs = round(
          Number(costsTotal).toFixed(5),
          2
        );
        this.newPurchaseOrder[singlePurchaseOrderIndex].iva = toFixedNoRound(
          iva,
          3
        );
        this.newPurchaseOrder[singlePurchaseOrderIndex].ieps = toFixedNoRound(
          ieps,
          3
        );
        this.newPurchaseOrder[singlePurchaseOrderIndex].EXENTO = toFixedNoRound(
          excent,
          3
        );
      }
      this.globalTotal = 0;
      this.globalSubtotal = 0;
      this.globalIva = 0;
      this.globalIeps = 0;
      this.globalCost = 0;
      this.globalDiscount = 0;
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        this.globalTotal += singlePurchaseOrder.total;
        this.globalSubtotal += singlePurchaseOrder.subtotal;
        this.globalIva += singlePurchaseOrder.iva;
        this.globalIeps += singlePurchaseOrder.ieps;
        this.globalCost += singlePurchaseOrder.costs;
        this.globalDiscount += singlePurchaseOrder.discounts;
      }
      this.globalTotal = round(this.globalTotal, 2);
      this.globalSubtotal = round(this.globalSubtotal, 2);
      this.globalIva = round(this.globalIva, 2);
      this.globalIeps = round(this.globalIeps, 2);
      this.globalCost = round(this.globalCost, 2);
      this.globalDiscount = round(this.globalDiscount, 2);
    },
    updateArticlePrice(articleInformation, value) {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [
          singleItemIndex,
          singleItem,
        ] of singlePurchaseOrder.currentItems.entries()) {
          if (
            singleItem.article &&
            articleInformation.article &&
            singleItem.article._id === articleInformation.article._id
          ) {
            this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
              singleItemIndex
            ].price = value;
          }
        }
      }
    },
    openAddArticlesSameWord() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddSameWordArticle,
        props: {
          providerInformation: this.selectedProvider,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddMultipleArticles() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectMultipleArticles,
        props: {
          currentArticles: this.newPurchaseOrder[0].currentItems,
          providerInformation: this.selectedProvider,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    getRandomString() {
      return createRandomString(6);
    },
    openSearchArticle(item) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectArticle,
        props: {
          currentArticles: this.newPurchaseOrder[0].currentItems,
          providerInformation: this.selectedProvider,
          renglon: item.RENGLON,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    autoCompleteProviders(value) {
      if (!this.providers) {
        return [];
      }
      return this.providers.filter((singlProvider) => {
        return (
          singlProvider.NOMBRE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singlProvider.CLAVE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    openKardex() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: WarehousesTransfers,
        props: {
          type: "1",
          showTitle: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    roundQuantities() {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [
          singleArticleIndex,
          singleArticle,
        ] of singlePurchaseOrder.currentItems.entries()) {
          this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
            singleArticleIndex
          ].quantity = Math.ceil(singleArticle.quantity);
        }
      }

      this.updateTotals();
      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Redondeo realizado con éxito",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {},
        cancelText: "cancelar",
        canCancel: false,
      });
    },
    roundQuantitiesSpecial() {
      for (const [
        singlePurchaseOrderIndex,
        singlePurchaseOrder,
      ] of this.newPurchaseOrder.entries()) {
        for (const [
          singleArticleIndex,
          singleArticle,
        ] of singlePurchaseOrder.currentItems.entries()) {
          this.newPurchaseOrder[singlePurchaseOrderIndex].currentItems[
            singleArticleIndex
          ].quantity = Math.ceil(singleArticle.quantity / 5) * 5;
        }
      }

      this.updateTotals();
      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Redondeo realizado con éxito",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {},
        cancelText: "cancelar",
        canCancel: false,
      });
    },
    openSelectSale() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectSaleModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    getItemBackOrder(rowInformation, warehouseInformation) {
      let currentItemMissingQuantity = 0;

      for (const singlePurchaseOrder of this.purchaseOrders) {
        if (
          singlePurchaseOrder.STATUS_PED !== "CA" &&
          singlePurchaseOrder.STATUS_PED !== "C"
        ) {
          for (const singleItem of singlePurchaseOrder.PEDIDO_DET) {
            if (
              rowInformation.article &&
              singleItem.CLAVE_ART &&
              singleItem.CLAVE_ART._id === rowInformation.article._id &&
              singlePurchaseOrder.CLAVE_ALMACEN._id === warehouseInformation._id
            ) {
              if (singleItem.CANTIDAD_PR > 0) {
                currentItemMissingQuantity += singleItem.CANTIDAD_PR;
              }
            }
          }
        }
      }

      return currentItemMissingQuantity;
    },
    openImportPurchaseOrder() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "requestOrder",
          nameImport: "Orden de compra",
          localConversion: true,
          localConversionName: "requestOrderConverted",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    getArticleQuantity(purchaseOrderIndex, article) {
      for (const [singleItemIndex, singleItem] of this.newPurchaseOrder[
        purchaseOrderIndex
      ].currentItems.entries()) {
        if (
          singleItem.article &&
          singleItem.article._id.toString() === article._id.toString()
        ) {
          return this.newPurchaseOrder[purchaseOrderIndex].currentItems[
            singleItemIndex
          ].quantity;
        }
      }

      return 0;
    },
    changeArticleQuantity(purchaseOrderIndex, article, value) {
      for (const [singleItemIndex, singleItem] of this.newPurchaseOrder[
        purchaseOrderIndex
      ].currentItems.entries()) {
        if (
          singleItem.article &&
          singleItem.article._id.toString() === article._id.toString()
        ) {
          this.newPurchaseOrder[purchaseOrderIndex].currentItems[
            singleItemIndex
          ].quantity = value;
        }
      }
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        const providerWithProps = { ...singleProvider };

        return providerWithProps;
      })
        .filter((singleProvider) => {
          return (
            !singleProvider.TIPO_PRV ||
            singleProvider.TIPO_PRV === "PR" ||
            singleProvider.TIPO_PRV === "PA"
          );
        })
        .sort((a, b) => {
          return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
        });
    },
    checkDisable() {
      if (this.selectedProvider && this.selectedWarehouses.length !== 0) {
        return false;
      } else {
        return true;
      }
    },
    checkDisableItems() {
      for (let singlePurchaseOrder of this.newPurchaseOrder) {
        for (const singleItem of singlePurchaseOrder.currentItems) {
          if (singleItem.article) return false;
        }
      }
      return true;
    },
    user() {
      return this.$store.getters.USER;
    },
    users() {
      return this.$store.getters.USERS;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "CO" || singleEmployee.TIPO === "VC";
      });
    },
    purchaseOrders() {
      return this.$store.getters.PURCHASEORDERS.map((singlePurchaseOrder) => {
        let purchaseOrderWithProps = { ...singlePurchaseOrder };
        return purchaseOrderWithProps;
      }).filter((singlePurchaseOrder) => {
        return singlePurchaseOrder.RESTA > 0;
      });
    },
  },
  watch: {
    creationDate() {
      this.startingDateFormated = moment(
        this.newPurchaseOrder.creationDate
      ).format("DD-MM-YYYY HH:mm");
    },
    // Restarts the request order if the provider is restarted
    selectedProvider() {
      if (this.selectedProvider) {
        this.selectedWarehouses = [];
        this.purchaseOrder = [];

        //Update articles to select only articles with selected provider price
        this.providerArticles = [];
        for (const singleArticle of this.articles) {
          let articleWithProps = { ...singleArticle };
          articleWithProps.price = 0;
          articleWithProps.costTotal = 0;
          articleWithProps.discountTotal = 0;
          articleWithProps.costs = [];
          articleWithProps.discounts = [];
          articleWithProps.quantity = 1;
          for (const singleProviderPrice of articleWithProps.PRECIOS_PROV) {
            if (singleProviderPrice.CLAVE_PRV === this.selectedProvider._id) {
              articleWithProps.costs = singleProviderPrice.COSTOS;
              articleWithProps.discounts = singleProviderPrice.DESCS;
              articleWithProps.price = singleProviderPrice.COSTO;
              articleWithProps.providerCode = singleProviderPrice.CLAVE_ARTPRV;
              this.providerArticles.push(articleWithProps);
            }
          }
          this.providerArticles.sort((a, b) =>
            a.DESCRIPCION > b.DESCRIPCION ? 1 : -1
          );
        }
      }
    },
    employees() {
      for (const singleUser of this.users) {
        if (singleUser.email === this.user.email) {
          for (const singleEmployee of this.employees) {
            if (singleUser._id === singleEmployee.USER) {
              this.buyer = singleEmployee._id;
            }
          }
        }
      }
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "add-request-orders-list-columns"
        );
      },
      deep: true,
    },
  },
  filters: {
    currency(value) {
      return value.toFixed(5);
    },
    weight(value) {
      value = value.toString();
      return `${value} Kg`;
    },
    volume(value) {
      value = value.toString();
      return `${value} M³`;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../style/AddPurchaseOrder.css";
</style>
